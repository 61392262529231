const TYPE = `[CONVERSATIONS]`;

const types = {
    FETCH_START: `${TYPE} FETCH START`,
    FETCH_SUCCESS: `${TYPE} FETCH SUCCESS`,
    FETCH_ERROR: `${TYPE} FETCH ERROR`,

    FETCH_ONE_START: `${TYPE} FETCH ONE START`,
    FETCH_ONE_SUCCESS: `${TYPE} FETCH ONE SUCCESS`,
    FETCH_ONE_ERROR: `${TYPE} FETCH ONE ERROR`,

    CLEAR_CONVERSATION: `${TYPE} CLEAR CONVERSATION`,

    ADD_LAST_MESSAGE: `${TYPE} ADD LAST MESSAGE`,

    RESET_ERROR: `${TYPE} RESET ERROR`,
}

export default types;