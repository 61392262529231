import styled from 'styled-components';

const Header = styled.div`
    padding: 16px;
    color: var(--text);
    border-radius: 8px 8px 0px 0px;
    display: flex;
    justify-content: center;
`;

export default Header;
