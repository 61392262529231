const TYPE = `[APPOINTMENTS]`;

const types = {
    FETCH_START: `${TYPE} FETCH START`,
    FETCH_SUCCESS: `${TYPE} FETCH SUCCESS`,
    FETCH_ERROR: `${TYPE} FETCH ERROR`,

    FETCH_ONE_START: `${TYPE} FETCH ONE START`,
    FETCH_ONE_SUCCESS: `${TYPE} FETCH ONE SUCCESS`,
    FETCH_ONE_ERROR: `${TYPE} FETCH ONE ERROR`,

    FETCH_PENDING_START: `${TYPE} FETCH PENDING START`,
    FETCH_PENDING_SUCCESS: `${TYPE} FETCH PENDING SUCCESS`,
    FETCH_PENDING_ERROR: `${TYPE} FETCH PENDING ERROR`,

    FETCH_UPCOMING_START: `${TYPE} FETCH UPCOMING START`,
    FETCH_UPCOMING_SUCCESS: `${TYPE} FETCH UPCOMING SUCCESS`,
    FETCH_UPCOMING_ERROR: `${TYPE} FETCH UPCOMING ERROR`,

    RESERVE_START: `${TYPE} RESERVE START`,
    RESERVE_SUCCESS: `${TYPE} RESERVE SUCCESS`,
    RESERVE_ERROR: `${TYPE} RESERVE ERROR`,

    ACCEPT_START: `${TYPE} ACCEPT START`,
    ACCEPT_SUCCESS: `${TYPE} ACCEPT SUCCESS`,
    ACCEPT_ERROR: `${TYPE} ACCEPT ERROR`,

    CONFIRM_START: `${TYPE} CONFIRM START`,
    CONFIRM_SUCCESS: `${TYPE} CONFIRM SUCCESS`,
    CONFIRM_ERROR: `${TYPE} CONFIRM ERROR`,

    CANCEL_START: `${TYPE} CANCEL START`,
    CANCEL_SUCCESS: `${TYPE} CANCEL SUCCESS`,
    CANCEL_ERROR: `${TYPE} CANCEL ERROR`,

    REJECT_START: `${TYPE} REJECT START`,
    REJECT_SUCCESS: `${TYPE} REJECT SUCCESS`,
    REJECT_ERROR: `${TYPE} REJECT ERROR`,

    GET_SERVER_TIME_START: `${TYPE} GET SERVER TIME START`,
    GET_SERVER_TIME_SUCCESS: `${TYPE} GET SERVER TIME SUCCESS`,
    GET_SERVER_TIME_ERROR: `${TYPE} GET SERVER TIME ERROR`,

    CLEAR_CURRENT: `${TYPE} CLEAR CURRENT`,

    RESET_ERROR: `${TYPE} RESET ERROR`,
}

export default types;