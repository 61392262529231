const CARD_BRANDS = {
    VISA: 'visa',
    MASTERCARD: 'mastercard',
    AMEX: 'amex',
    DISCOVER: 'discover',
    DINERS: 'diners',
    JCB: 'jcb',
    UNIONPAY: 'unionpay',
    UNKNOWN: 'unknown',
}

export default CARD_BRANDS;
