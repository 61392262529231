export const PRIMARY_GREEN = "#717402";
export const GREEN = "#B3CC1D";
export const GOLDEN = "#E5BE0A";
export const GOLDEN_HIGHLIGHT = "#EFF284";
export const GREEN_HIGHLIGHT = "#C3F284";
export const DARK_TEXT = "#484848";
export const DARKER_TEXT = "#313131";
export const PLACEHOLDER_TEXT = "#A5A5A5";
export const RED = "#cf142b";
export const RED_LIGHT = "#cf142b55";
