const TYPE = `[THERAPISTS]`;

export default {
    FETCH_START: `${TYPE} FETCH START`,
    FETCH_SUCCESS: `${TYPE} FETCH SUCCESS`,
    FETCH_ERROR: `${TYPE} FETCH ERROR`,

    FETCH_PROFILE_START: `${TYPE} FETCH PROFILE START`,
    FETCH_PROFILE_SUCCESS: `${TYPE} FETCH PROFILE SUCCESS`,
    FETCH_PROFILE_ERROR: `${TYPE} FETCH PROFILE ERROR`,

    RESET_ERROR: `${TYPE} RESET ERROR`,
}