const TYPE = `[USER]`;

const Types = {
    FETCH_START: `${TYPE} FETCH START`,
    FETCH_SUCCESS: `${TYPE} FETCH SUCCESS`,
    FETCH_ERROR: `${TYPE} FETCH ERROR`,
    
    UPDATE_IMAGE_START: `${TYPE} UPDATE IMAGE START`,
    UPDATE_THERAPIST_START: `${TYPE} UPDATE THERAPIST START`,
    UPDATE_START: `${TYPE} UPDATE START`,
    UPDATE_SUCCESS: `${TYPE} UPDATE SUCCESS`,
    UPDATE_ERROR: `${TYPE} UPDATE ERROR`,

    SETUP_INTENT_START: `${TYPE} SETUP INTENT START`,
    SETUP_INTENT_SUCCESS: `${TYPE} SETUP INTENT SUCCESS`,
    SETUP_INTENT_ERROR: `${TYPE} SETUP INTENT ERROR`,

    DELETE_PAYMENT_METHOD_START: `${TYPE} DELETE PAYMENT METHOD START`,
    DELETE_PAYMENT_METHOD_SUCCESS: `${TYPE} DELETE PAYMENT METHOD SUCCESS`,
    DELETE_PAYMENT_METHOD_ERROR: `${TYPE} DELETE PAYMENT METHOD ERROR`,

    FETCH_PAYMENT_METHODS_START: `${TYPE} FETCH PAYMENT METHODS START`,
    FETCH_PAYMENT_METHODS_SUCCESS: `${TYPE} FETCH PAYMENT METHODS SUCCESS`,
    FETCH_PAYMENT_METHODS_ERROR: `${TYPE} FETCH PAYMENT METHODS ERROR`,

    ACCEPT_INVITATION_START: `${TYPE} ACCEPT INVITATION START`,
    ACCEPT_INVITATION_SUCCESS: `${TYPE} ACCEPT INVITATION SUCCESS`,
    ACCEPT_INVITATION_ERROR: `${TYPE} ACCEPT INVITATION ERROR`,

    FETCH_ACCOUNT_INFORMATION_START: `${TYPE} FETCH ACCOUNT INFORMATION START`,
    FETCH_ACCOUNT_INFORMATION_SUCCESS: `${TYPE} FETCH ACCOUNT INFORMATION SUCCESS`,
    FETCH_ACCOUNT_INFORMATION_ERROR: `${TYPE} FETCH ACCOUNT INFORMATION ERROR`,

    ADD_DOCUMENTATION: `${TYPE} ADD DOCUMENTATION`,
    UPDATE_DOCUMENTATION: `${TYPE} UPDATE DOCUMENTION`,
    DELETE_DOCUMENTATION: `${TYPE} DELETE DOCUMENTION`,

    RESET_ERROR: `${TYPE} RESET ERROR`,
}

export default Types;